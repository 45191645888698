var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.action === 'Save' ? 'Add' : 'Update')+" Router")])]),_c('v-form',{ref:"form",attrs:{"method":"post","action":"/","lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.saveOrUpdate()}}},[_c('v-card-text',[_c('v-container',[_c('v-expand-transition',[_c('v-row',[_vm._l((_vm.form.hosts),function(host,index){return [_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.hosts[index]),expression:"form.hosts[index]"}],key:index,staticClass:"pd-0",attrs:{"cols":"12"}},[_c('v-expand-transition',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":index === 0 ? 'Router host' : 'Additional host',"placeholder":index === 0 ? 'e.g. 192.168.88.1' : 'e.g. 172.16.0.60',"rules":[function (v) { return index === 0 ? (!!v || 'Host is required') : true; }],"required":index === 0,"error":_vm.form.errors.has(("hosts." + index + ".host")),"error-messages":_vm.form.errors.get(("hosts." + index + ".host")),"outlined":"","hint":index === 0 ? 'Enter the IP address or hostname of the MikroTik router.' : 'Enter an additional IP address or hostname for the router.'},model:{value:(host.host),callback:function ($$v) {_vm.$set(host, "host", $$v)},expression:"host.host"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"2"}},[(index > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.removeHost(index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiMinusCircle))])],1)]}}],null,true)},[_c('span',[_vm._v("Remove additional host")])]):_vm._e(),(index === _vm.form.hosts.length - 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.addHost}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlusCircle))])],1)]}}],null,true)},[_c('span',[_vm._v("Add additional host")])]):_vm._e()],1)],1)],1)],1)]}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Username","placeholder":"e.g. admin","rules":[
                      function (v) { return !!v || 'Username is required'; } ],"error":_vm.form.errors.has('username'),"error-messages":_vm.form.errors.get('username'),"outlined":"","hint":"Provide the username to log in to the MikroTik router."},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":_vm.showPassword ? _vm.icons.mdiEyeOutline : _vm.icons.mdiEyeOffOutline,"type":_vm.showPassword ? 'text' : 'password',"label":"Password","placeholder":"Password to connect with the router","error":_vm.form.errors.has('password'),"error-messages":_vm.form.errors.get('password'),"outlined":"","hint":"Enter the password associated with the provided username for router access."},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Port","placeholder":"e.g. 8728","rules":[
                      function (v) { return !!v || 'Port is required'; },
                      function (v) { return (!isNaN(parseFloat(v)) && isFinite(v) && v >= 0 && v <= 65535) || 'Please enter a valid port number between 0 and 65535'; } ],"error":_vm.form.errors.has('port'),"error-messages":_vm.form.errors.get('port'),"outlined":"","hint":"Specify the port number used to connect to the MikroTik router (usually 8728 or 8729 for API access)."},model:{value:(_vm.form.port),callback:function ($$v) {_vm.$set(_vm.form, "port", $$v)},expression:"form.port"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.stations,"item-text":"name","item-value":"id","label":"Select a station","rules":[
                      function (v) { return !!v || 'Station is required'; } ],"error":_vm.form.errors.has('station_id'),"error-messages":_vm.form.errors.get('station_id'),"outlined":"","hint":"Choose a station from the list to associate the router with the selected station."},model:{value:(_vm.form.station_id),callback:function ($$v) {_vm.$set(_vm.form, "station_id", $$v)},expression:"form.station_id"}})],1)],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit","loading":_vm.form.busy}},[_vm._v(" "+_vm._s(_vm.action)+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }