<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} Router</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-expand-transition>
                <v-row>
                  <template
                    v-for="(host, index) in form.hosts"
                  >
                    <v-col
                      v-show="form.hosts[index]"
                      :key="index"
                      cols="12"
                      class="pd-0"
                    >
                      <v-expand-transition>
                        <v-row>
                          <v-col cols="10">
                            <v-text-field
                              v-model="host.host"
                              :label="index === 0 ? 'Router host' : 'Additional host'"
                              :placeholder="index === 0 ? 'e.g. 192.168.88.1' : 'e.g. 172.16.0.60'"
                              :rules="[v => index === 0 ? (!!v || 'Host is required') : true]"
                              :required="index === 0"
                              :error="form.errors.has(`hosts.${index}.host`)"
                              :error-messages="form.errors.get(`hosts.${index}.host`)"
                              outlined
                              :hint="index === 0 ? 'Enter the IP address or hostname of the MikroTik router.' : 'Enter an additional IP address or hostname for the router.'"
                            ></v-text-field>
                          </v-col>

                          <v-col
                            cols="2"
                            class="d-flex align-center"
                          >
                            <v-tooltip
                              v-if="index > 0"
                              bottom
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="removeHost(index)"
                                >
                                  <v-icon>{{ icons.mdiMinusCircle }}</v-icon>
                                </v-btn>
                              </template>
                              <span>Remove additional host</span>
                            </v-tooltip>

                            <v-tooltip
                              v-if="index === form.hosts.length - 1"
                              bottom
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="addHost"
                                >
                                  <v-icon>{{ icons.mdiPlusCircle }}</v-icon>
                                </v-btn>
                              </template>
                              <span>Add additional host</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-expand-transition>
                    </v-col>
                  </template>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.username"
                      label="Username"
                      placeholder="e.g. admin"
                      :rules="[
                        v => !!v || 'Username is required',
                      ]"
                      :error="form.errors.has('username')"
                      :error-messages="form.errors.get('username')"
                      outlined
                      hint="Provide the username to log in to the MikroTik router."
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.password"
                      :append-icon="showPassword ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                      :type="showPassword ? 'text' : 'password'"
                      label="Password"
                      placeholder="Password to connect with the router"
                      :error="form.errors.has('password')"
                      :error-messages="form.errors.get('password')"
                      outlined
                      hint="Enter the password associated with the provided username for router access."
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.port"
                      label="Port"
                      placeholder="e.g. 8728"
                      :rules="[
                        v => !!v || 'Port is required',
                        v => (!isNaN(parseFloat(v)) && isFinite(v) && v >= 0 && v <= 65535) || 'Please enter a valid port number between 0 and 65535',
                      ]"
                      :error="form.errors.has('port')"
                      :error-messages="form.errors.get('port')"
                      outlined
                      hint="Specify the port number used to connect to the MikroTik router (usually 8728 or 8729 for API access)."
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="form.station_id"
                      :items="stations"
                      item-text="name"
                      item-value="id"
                      label="Select a station"
                      :rules="[
                        v => !!v || 'Station is required',
                      ]"
                      :error="form.errors.has('station_id')"
                      :error-messages="form.errors.get('station_id')"
                      outlined
                      hint="Choose a station from the list to associate the router with the selected station."
                    ></v-select>
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import {
  mdiEyeOutline, mdiEyeOffOutline, mdiCheck, mdiAlertCircle, mdiPlusCircle, mdiMinusCircle,
} from '@mdi/js'

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    router: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      hosts: [{ host: '' }],
      host: '',
      username: '',
      password: '',
      port: '8728',
      station_id: '',
      id: '',
    }),
    action: 'Save',
    stations: [],
    importProgress: [],
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
      mdiCheck,
      mdiAlertCircle,
      mdiPlusCircle,
      mdiMinusCircle,
    },
    showPassword: false,
  }),
  watch: {
    router() {
      if (Object.keys(this.router).length > 0) {
        this.form.update(this.router)
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
  },
  created() {
    this.fetchStations()
  },
  methods: {
    addHost() {
      this.form.hosts.push({ host: '' })
    },
    removeHost(index) {
      this.form.hosts.splice(index, 1)
    },
    closeDialog() {
      this.$emit('close')
      this.$refs.form.resetValidation()
      this.form = new Form({
        hosts: [{ host: '' }],
        username: '',
        password: '',
        port: '8728',
        station_id: '',
        id: '',
      })
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        if (this.action === 'Save') {
          this.saveRouter()
        } else {
          console.log('update')
          this.updateRouter()
        }
      }
    },
    saveRouter() {
      this.form
        .post('routers')
        .then(response => {
          this.$toast.success('Router added successfully')
          this.$emit('close')
          this.$emit('router-added', response.data)
          this.$refs.form.reset()
          setTimeout(() => {
            this.$toast.info('Router data is being imported. You will be notified via sms when it is ready.')
          }, 600)
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    updateRouter() {
      this.form
        .put(`routers/${this.form.id}`)
        .then(response => {
          this.$toast.success('Router updated successfully')
          this.$emit('close')
          this.$emit('router-updated', response.data)
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    fetchStations() {
      axios.get('/stations?perPage=all')
        .then(response => {
          this.stations = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
  },
}
</script>
